import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Body from 'components/body';
import Heading from 'components/heading';
import RichText from 'components/rich-text';

const TextBlock = ({
  as = 'h2',
  size = 'sm',
  preHeading = '',
  heading,
  subheading = '',
  body = '',
  className = '',
  bodyClassName = 'max-w-[54ch]',
  balanceText = true,
  align = 'left',
  isReversed = false,
}) => {
  const {
    bodySize,
    headingSize,
    headingClassName,
    preHeadingClassName,
    subheadingClassName,
  } = {
    sm: {
      bodySize: 'sm',
      headingSize: 'xl',
      headingClassName: 'mb-3',
      preHeadingClassName: 'text-sm font-sans font-medium leading-normal mb-3',
      subheadingClassName: 'text-md font-sans leading-normal mb-3',
    },
    md: {
      bodySize: 'sm',
      headingSize: '3xl',
      headingClassName: 'mb-6',
      preHeadingClassName: 'text-md font-sans font-medium leading-normal mb-6',
      subheadingClassName: 'text-lg font-sans leading-normal mb-6',
    },
    lg: {
      bodySize: 'lg',
      headingSize: '4xl',
      headingClassName: 'mb-6',
      preHeadingClassName: 'text-md font-sans font-medium leading-normal mb-6',
      subheadingClassName: 'text-lg font-sans leading-normal mb-6',
    },
  }[size];

  const alignClassName = {
    left: 'text-left',
    center: 'text-center',
  }[align];

  const colorClassName = isReversed ? 'text-white' : 'text-black';

  return (
    <section className={classNames(alignClassName, className)}>
      {preHeading && (
        <div className={classNames(colorClassName, preHeadingClassName)}>
          {preHeading}
        </div>
      )}
      <Heading
        as={as}
        balanceText={balanceText}
        className={headingClassName}
        html={heading}
        isReversed={isReversed}
        size={headingSize}
      />
      {subheading && (
        <p className={classNames(colorClassName, subheadingClassName)}>
          <RichText html={subheading} />
        </p>
      )}
      {body && (
        <Body
          className={bodyClassName}
          html={body}
          isReversed={isReversed}
          size={bodySize}
        />
      )}
    </section>
  );
};

TextBlock.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'div']),
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  preHeading: PropTypes.string,
  subheading: PropTypes.string,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string,
  className: PropTypes.string,
  bodyClassName: PropTypes.string,
  balanceText: PropTypes.bool,
  align: PropTypes.oneOf(['left', 'center']),
  isReversed: PropTypes.bool,
};

export default TextBlock;
