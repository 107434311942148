import PropTypes from 'prop-types';
import React from 'react';

import RichText from 'components/rich-text';

function Body({
  html,
  size = 'sm',
  className = 'max-w-[54ch]',
  isReversed = false,
  ...props
}) {
  const sizeStyles = {
    sm: 'text-sm',
    md: 'text-md',
    lg: 'text-lg',
  }[size];

  const colorClass = isReversed ? 'text-white' : 'text-black';
  const classes = `mb-4 last:mb-0 text-sans leading-normal ${colorClass} ${sizeStyles} ${className}`;

  return <RichText classNames={{ p: classes }} html={html} {...props} />;
}

Body.propTypes = {
  html: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  className: PropTypes.string,
  isReversed: PropTypes.bool,
};

export default Body;
