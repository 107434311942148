import { Link } from 'gatsby';
import * as React from 'react';

import Container from 'components/container';
import Footer from 'components/footer';
import Header from 'components/header';
import TextBlock from 'components/text-block';

const NotFoundPage = () => (
  <div className='flex flex-col min-h-screen'>
    <div className='flex-grow flex flex-col'>
      <Header />
      <Container className='mb-[10vh] flex-grow flex flex-col justify-center'>
        <main className='max-w-[768px] mx-auto'>
          <TextBlock
            align='center'
            body='<p>Sorry, the resource you requested could not be found.</p>'
            bodyClassName='text-center'
            heading='Page Not Found'
            size='md'
            subheading='Error 404'
          />
          <p className='text-center'>
            Not sure where to go? Try visiting our{' '}
            <Link className='underline' to='/'>
              home page
            </Link>{' '}
            or{' '}
            <Link className='underline' to='/contact-us'>
              contacting us
            </Link>
            .
          </p>
        </main>
      </Container>
    </div>
    <Footer />
  </div>
);

export default NotFoundPage;
